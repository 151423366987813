import React, { memo, useCallback, useState } from 'react';
import IconDelete from '../../components/Icons/IconDelete';
import { Tooltip } from 'antd';
import PopConfirm from '@components/PopConfirm';

const BtnDelete = memo(({ uid, name, onConfirm, icon }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);

   const showModal = useCallback(() => {
      setIsModalVisible(true);
   }, []);

   const handleOk = useCallback(async () => {
      if (uid) {
         setIsModalVisible(false);
         await onConfirm(uid);
      }
   }, [uid, onConfirm]);

   const onPopConfirmVisibleChange = useCallback((isOpen) => {
      setIsModalVisible(isOpen);
   }, []);

   return (
      <>
         <PopConfirm
            isOpen={isModalVisible}
            zIndex={4039}
            onVisibleChange={onPopConfirmVisibleChange}
            onConfirm={handleOk}
         >
            <Tooltip placement="bottom" title="Delete" color="black">
               <button
                  className={`button-icon action-delete ${
                     isModalVisible ? 'active' : ''
                  }`}
                  onClick={showModal}
               >
                  {icon ? icon : <IconDelete />}
               </button>
            </Tooltip>
         </PopConfirm>
      </>
   );
});

export default BtnDelete;
