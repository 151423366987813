import RequestModel from '@apis/models/RequestModel';
import { createCancelToken } from '@apis/ServiceController';
import { message } from '@components/Message';
import IconAdd2 from '@icons/IconAdd2';
import IconEdit from '@icons/IconEdit';
import { tagService } from '@services/tagService';
import { convertToDate } from '@utils/common';
import constant from '@utils/constant';
import BtnDelete from '@views_admin/components/BtnDelete';
import ContentLoading from '@views_admin/ContentLoading';
import BtnEdit from '@views_admin/tags/BtnEdit';
import { Tooltip } from 'antd';
import Search from '../../../views_admin/layout/Search';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Sort from '@views_admin/components/Sort';
import CommonTable from '@views_admin/components/CommonTable';
import { SortType } from '@apis/enums/SortType';
import IconDelete2 from '@icons/IconDelete2';
import IconCheck from '@icons/IconCheck';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Name',
      value: 'name',
   },
];

const Tags = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();
   const tagsCanceler = useRef(null);

   const reupdateStorageData = async () => {
      if (tagsCanceler.current?.cancel) {
         tagsCanceler.current.cancel();
      }
      tagsCanceler.current = await tagService.loadTags({
         page: 1,
         page_size: constant.defaultPagesize,
         order_by: 'updated_at',
         sort_type: 'DESC',
      });
   };

   const onEditSuccess = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
      }));
      reupdateStorageData();
   }, []);

   const reloadData = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: 1,
      }));
      reupdateStorageData();
   }, []);

   const columns = useMemo(() => {
      const handleDelete = async (uid) => {
         if (uid) {
            message.loading('Deleting...', 0);
            const responseData = await tagService.deleteTag(uid);
            setTimeout(async () => {
               if (responseData.isSuccess) {
                  reloadData();
                  message.success('Delete successfully.');
               } else {
                  message.error(responseData.message);
               }
            }, 500);
         }
      };

      return [
         {
            title: 'Name',
            dataIndex: 'name',
         },
         {
            title: 'Description',
            dataIndex: 'description',
            render: (text, record) => (
               <p className="description description-col-content">
                  {text && text.trim() ? text : <span className="nothing">--</span>}
               </p>
            ),
         },
         {
            title: 'Used',
            dataIndex: 'used',
            render: (v) => <>{v ? <IconCheck /> : <span className="nothing">--</span>}</>,
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Updated date',
            dataIndex: 'updated_at',
            render: (text) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <BtnEdit
                     className="button-icon action-normal"
                     tooltip="Edit"
                     rowData={record}
                     onSuccess={onEditSuccess}
                  >
                     <IconEdit />
                  </BtnEdit>
                  {!record.used && (
                     <BtnDelete
                        uid={record.uid}
                        name={record.name}
                        icon={<IconDelete2 />}
                        onConfirm={handleDelete}
                     />
                  )}
               </div>
            ),
         },
      ];
   }, [reloadData, onEditSuccess]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadData = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await tagService.getTagsMatrix(
            requestBody,
            cancelToken.current?.token
         );
         console.log('responseData', responseData);
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   const refresh = useCallback(() => {
      setFilter({
         ...filter,
         orderBy: '',
         sortType: '',
         page: 1,
      });
      reupdateStorageData();
   }, [filter]);

   return (
      <>
         <SEO title={'Tags - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Tags</label>
            </div>
            <div className="content-head__right">
               <BtnEdit className="outline-btn btn-add-new-item" onSuccess={refresh}>
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Tag
               </BtnEdit>
            </div>
         </div>
         <div className="content-body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search
                        placeholder="Search in tags"
                        onSearch={(v) => setSearchText(v)}
                     />
                  </div>
                  <div className="admin-table__header--right">
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               {
                  <CommonTable
                     rowKey="uid"
                     unit="Tags"
                     dataSource={data.content}
                     columns={columns}
                     page={filter.page}
                     pageSize={filter.pageSize}
                     totalRows={data.totalRows}
                     onChange={onTableChange}
                  />
               }
            </div>
         </div>
      </>
   );
});

export default Tags;
