import React, { memo, useCallback, useEffect, useState } from 'react';
import { Tooltip, Form } from 'antd';
import IconEdit from '../../components/Icons/IconEdit';
import EditingDrawer from '../components/EditingDrawer';
import RequestModel from '../../apis/models/RequestModel';
import { message } from '../../components/Common/Message';
import _forIn from 'lodash/forIn';
import { InputType } from '@utils/enums/InputType';
import { tagService } from '@services/tagService';

const formDefinitions = [
   {
      name: 'name',
      label: 'Name',
      placeholder: 'Tag name',
      rules: [
         {
            required: true,
            message: 'Please input your Tag name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
];

const BtnEdit = memo(({ rowData, onSuccess, tooltip, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      if (rowData) {
         const valueObj = {};
         _forIn(rowData, (value, key) => {
            valueObj[key] = value;
         });
         form.setFieldsValue(valueObj);
      }
      setIsDrawerOpen(true);
   }, [form, rowData]);

   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   useEffect(
      () => {
         if (rowData) {
            form.setFieldsValue({
               name: rowData?.name || '',
               description: rowData?.description || '',
            });
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values?.name || '',
            description: values?.description || '',
         };
         const requestBody = new RequestModel(body);
         let responseData;
         // is update
         if (rowData?.uid) {
            responseData = await tagService.updateTag(rowData.uid, requestBody);
         }
         // is create
         else {
            responseData = await tagService.createTag(requestBody);
         }
         if (responseData?.isSuccess) {
            onSuccess();
            message.success(rowData ? `You successfully updated tag data.` : `Create new tag successfully.`);
            onClose();
            form.resetFields();
         } else {
            message.error(responseData.message);
         }

         setSubmitting(false);
      },
      [onSuccess, rowData, form, onClose]
   );

   const tooltipProps = !tooltip ? { visible: false } : {};

   return (
      <>
         <Tooltip {...tooltipProps} placement="bottom" title={tooltip || ''} color="black">
            <button className={ownProps.className} onClick={showDrawer}>
               {ownProps.children}
            </button>
         </Tooltip>
         <EditingDrawer
            title={rowData ? 'Edit Tag' : 'Create a new Tag'}
            isOpen={isDrawerOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            currentUid={rowData?.uid}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnEdit;
