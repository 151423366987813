import { FETCH_TAGS } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { tokenUtil } from '@utils/token';
import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';

const loadTags = async (body) => {
   return await dispatch(
      Request.post(FETCH_TAGS, {
         data: body,
      })
   );
};

const getTagsMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_TAG_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const createTag = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_TAG,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const updateTag = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_TAG,
      params: {
         uid,
      },
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteTag = async (uid, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_TAG,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

export const tagService = {
   loadTags,
   getTagsMatrix,
   createTag,
   updateTag,
   deleteTag,
};
